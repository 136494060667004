<template>
    <mf-box border-box without-inner-paddings>
        <template slot="header">
            <mf-checkbox
                v-if="options && options.length"
                class="filter__checkbox"
                @change="toggleAll"
                :checked="allOptionsSelected"
            >
                {{ type === 'vessel' ? 'My Vessel Lists' : 'My Quay Lists' }}
            </mf-checkbox>
            <div v-else>
                {{ type === 'vessel' ? 'My Vessel Lists' : 'My Quay Lists' }}
            </div>
        </template>
        <mf-divider />
        <div v-if="options && options.length">
            <template v-for="status in options">
                <mf-divider :key="`divider-${status.name}`" />
                <div class="filter__item" :key="status.name">
                    <mf-checkbox class="filter__checkbox" @change="status.checked = $event" :checked="status.checked">
                        <span class="status__icon" :class="`status__icon_${status.color}`" />
                        {{ status.name }}
                    </mf-checkbox>
                </div>
            </template>
        </div>
        <div v-else class="custom-lists__box">
            <div class="custom-lists__info">
                {{ type === 'vessel' ? "You haven't created any vessel lists" : "You haven't created any quay lists" }}
            </div>
            <mf-button color="blue" size="small" @click="routeToListsCreate">
                <mf-icon slot="icon" icon-name="icon-plus" height="12" width="12" />
                Create list
            </mf-button>
        </div>
    </mf-box>
</template>
<script>
    export default {
        name: 'CustomLists',
        props: {
            type: {
                type: String,
                default: '',
            },
            options: {
                type: Array,
                required: true,
            },
            allOptionsSelected: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            routeToListsCreate() {
                this.$router.push({ name: 'my-lists' });
            },
            toggleAll() {
                this.$emit('toggleAll');
            },
        },
    };
</script>
<style lang="scss" scoped>
    .custom-lists__box {
        padding: $space-20 $space-16;
        font-weight: normal;
        font-size: $font-14;
        line-height: $font-24;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .custom-lists__info {
        font-size: $font-14;
        line-height: $font-14;
        text-align: center;
        color: $marine-400;
        margin-bottom: $space-12;
    }

    .filter__item {
        padding: $space-14 $space-16;
    }
</style>
